.header{
  width: 100%;

}
.header-container{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.header-container img{
  height: 50px;
}
.nav-container{
  background-color: black;
  padding: 10px;

}

.nav-container nav a{
  text-decoration: none;
  color: white;
  /* margin-left: 20px; */
  padding: 10px 16px;
  font-size: large;
  
}

.nav-container nav a:hover{
  background-color: gainsboro;
  padding: 10px 16px;
  color: black;
}
.search-box{
  background-color: rgb(36, 35, 35);
  width: 100%;
  height: 80px;
 
}
.search-box input{
  margin: 0 auto;
  margin-top: 20px;
  width: 50%;
  height: 30px;
  margin-left: 269px;
  display: inline-block;
  background-color: transparent;
  border: none;
  color: #fff;
 
}
.products-container{
  display: flex;
  margin: 10px;
}

.products{
  border-right: 1px solid lightblue;
  width: 80%;

}
.product{
  display: flex;
  border-bottom: 1px solid grey;
  margin: 10px;
}
.product-img{
  margin-right: 10px;
}
.produt-detail h3{
  color: #00f;
  font-weight: 400;
  font-style: italic;
}
#add-to-cart-btn{
  width: 150px;
  height: 30px;
  background-color: orange;
  border-radius: 5px;
  border: gray;
  margin-bottom: 10px;
  cursor: pointer;
}
.cart{
  margin-left: 20px;
}
.cart h3,
.cart p{
  margin-left: 20px;
}

#order-total{
  color: red;
  font-weight: 700;
}